import React from "react";
import bottomLimiterSvg from './img/limiter.png';

export default function About(props){
    return (
        <div className="main_wrapper">
            {/* <img className="top_limiter" src={bottomLimiterSvg} /> */}
            {/* <div className="main_limmiter main_top_limmiter"></div> */}
            
            <div className="main_text_wrapper corres">
                <div className="main_stndart_text main_stndart_text_notmarg ">
                    First of all, Adanede is not a team. It is not a group of people united by the same activity or having similar goals. It is not a startup or a company. It does not report to anyone, it does not follow the rules, it is not guided by profit or any metrics. It is not tangible. It is not personal
                    <br></br>
                    <br></br>
                    Adanede is an idea. It is defined as it is and cannot be deleted, erased, or banned. All it can do is make people believe. And that belief gives people power. It does not seek to change anyone for now. It gathers individuals who hold the same idea somewhere very deep in their hearts
                    <br></br>
                    <br></br>
                    Why is it not people? People tend to be weak, or become weak over time. They shine for a period of time and then burn out. People can give up. People carry narrow values - they are restricted by gender, race, language, age, interests, jobs, schools they went to. An idea is universal, it is unbiased
                    <br></br>
                    <br></br>
                    Adanede was born and grew during the entire lifetime of the NFT space. It saw how promising it started and how deeply wrong it went. From the most prominent technology to a space of incompetent people led by greed. From collecting to trading. From building to scamming. From promises to complete silence
                    <br></br>
                    <br></br>
                    Adanede is still fragile for now. But with every new member, every new heart in which it resonates, it grows and strengthens. Every click you make, every word you say, makes it stronger and spreads even more strength amongst people
                    <br></br>
                    <br></br>
                    Its goal is to become the symbol that truly means something to people, that holds something valuable and sacred
                </div>
                
                <div className="main_stndart_text main_stndart_text_notmarg cont_text_mobile_co">
                First of all, Adanede is not a team. It is not a group of people united by the same activity or having similar goals. It is not a startup or a company. It does not report to anyone, it does not follow the rules, it does not guided by profit or any metrics. It is not tangible. It is not personal.
                <br></br>
                <br></br>
                Adanede is an idea. It is defined as it is and cannot be deleted, erased, banned. All it can do is to make people believe. And that belief gives people power. It does not seek of changing anyone for now. It gathers individuals who holds the same idea somewhere very deep in their hearts
                <br></br>
                <br></br>
                Why it is not people? People tend to be weak, or become weak over time. They shine for a period of time and then burn. People can give up. People carry narrow values - they restricted by gender, race, language, age, interests, jobs, schools they went to. Idea is universal, it is unbiased
                <br></br>
                <br></br>
                Adanede was born and grew during the whole lifetime of NFT space. It saw how promising it started and how deeply wrong it went. From the most prominent technology the world to a space of incompetent people led by greed. From collecting to trading. From building to scamming. From promises to complete silence
                <br></br>
                <br></br>
                Adanede is still fragile for now. But with every new member, every new heart in which it responds it grows and strengthens. Every click you make, every word you say, makes it stronger and spread even more strength amongst people back
                <br></br>
                <br></br>
                Its goal is to become the symbol that really means something to people, that holds something valuable and sacral
                </div>
            </div>
            {/* <div className="main_limmiter main_bottom_limmiter"></div> */}
            {/* <img className="bottom_limiter" src={bottomLimiterSvg} /> */}
        </div>
    )
}