import React from "react";
import BluredText from './../img/bluredText.png';
export default function Stats(props){
    return(
        <div className="main_wrapper">
            <div className="cont_text_mobile">
                <div className="bluST">
                    
                    <div className="main_stndart_text main_stndart_text3">In the last decade, there has been a significant increase in the use of technology worldwide, with over 4.6 billion people using the internet in 2021. This has led to a massive influx of data, with 2.5 quintillion bytes of data being created every day, a number that is expected to double every two years. As a result, there is an increasing need for advanced data analysis tools and techniques to make sense of this data and turn it into valuable insights.</div>
                    <div className="main_stndart_text main_stndart_text3">In addition to the growth of data, there has also been a rise in the use of artificial intelligence (AI) and machine learning (ML) technologies. The global AI market is expected to reach $733.7 billion by 2027, growing at a CAGR of 42.2% from 2020 to 2027. The use of AI and ML has revolutionized various industries, including healthcare, finance, and manufacturing, among others.</div>
                    <div className="main_stndart_text main_stndart_text3">However, with the growth of technology and data comes the challenge of ensuring data privacy and security. In 2021, the number of data breaches increased by 24% compared to the previous year, with over 1,000 data breaches reported. These breaches exposed over 150 billion records globally, highlighting the need for stronger cybersecurity measures.</div>
                    <div className="main_stndart_text main_stndart_text3">In conclusion, the rapid growth of technology and data presents both opportunities and challenges. As we continue to navigate this landscape, it is crucial to stay informed and adopt strategies that promote data privacy, security, and responsible use of AI and ML.</div>
                    {/* <div className="main_stndart_text main_stndart_text3">Overweight and obesity have become significant public health concerns worldwide. According to the World Health Organization, in 2020, more than 1.9 billion adults were overweight, with 650 million of them being obese. In the United States, more than 70% of adults are either overweight or obese, with 42.4% being obese.</div>
                    <div className="main_stndart_text main_stndart_text3">Unhealthy dietary habits are a major contributor to the obesity epidemic. In the US, more than 60% of the daily caloric intake comes from ultra-processed foods. A typical American diet is high in added sugars, saturated fats, and sodium, which are linked to chronic diseases such as type 2 diabetes, heart disease, and some cancers.</div>
                    <div className="main_stndart_text main_stndart_text3">The economic cost of obesity is also substantial. In the US, the estimated annual cost of obesity-related medical expenses and lost productivity is over $147 billion. Furthermore, the annual cost of treating obesity-related illnesses is projected to reach $1.2 trillion by 2025.</div>
                    <div className="main_stndart_text main_stndart_text3">Efforts to combat the obesity epidemic should focus on promoting healthy eating habits and physical activity. Initiatives such as implementing taxes on unhealthy foods, providing access to healthy food options, and promoting active transportation can help reduce the incidence of overweight and obesity. With concerted efforts from individuals, healthcare providers, and policymakers, it is possible to reverse the trend of rising obesity rates and improve public health.</div> */}
                    {/* <div className="main_stndart_title main_stndart_title1">Stage 2</div>
                    <div className="main_stndart_text main_stndart_text1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    </div>
                    <div className="main_stndart_title main_stndart_title1">Stage 3</div>
                    <div className="main_stndart_text main_stndart_text1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    </div>
                    <div className="main_stndart_title main_stndart_title1">Stage 4</div>
                    <div className="main_stndart_text main_stndart_text1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    </div> */}
                    {/* <div className="stat_texts_blur stat_texts_blur1">
                        First of all, Adanede is not a team. It is not a group of people united by the same activity or having similar goals. It is not a startup or a company. It does not report to anyone, it does not follow the rules, it does not guided by profit or any metrics. It is not tangible. It is not personal.
                        <br></br><br></br>
                        Adanede is an idea. It is defined as it is and cannot be deleted, erased, banned. All it can do is to make people believe. And that belief gives people power. It does not seek of changing anyone for now. It gathers individuals who holds the same idea somewhere very deep in their hearts
                    </div> */}
                    <div className="cont_stat_coms cont_stat_coms1">
                        <div title="/coming soon//" className="main_title s-128 glitcher">/coming soon//</div>
                    </div>
                </div>

                <div className="stat_texts_blur stat_texts_blur2">
                    First of all, Adanede is not a team. It is not a group of people united by the same activity or having similar goals. It is not a startup or a company. It does not report to anyone, it does not follow the rules, it does not guided by profit or any metrics. It is not tangible. It is not personal.
                    <br></br><br></br>
                    Adanede is an idea. It is defined as it is and cannot be deleted, erased, banned. All it can do is to make people believe. And that belief gives people power. It does not seek of changing anyone for now. It gathers individuals who holds the same idea somewhere very deep in their hearts. Adanede is an idea. It is defined as it is and cannot be deleted, erased, banned. All it can do is to make people believe. And that belief gives people power. It does not seek of changing anyone for now. It gathers individuals who holds the same idea somewhere very deep in their hearts
                </div>
                <div className="cont_stat_coms cont_stat_coms2 cont_stat_coms22">
                    <div title="Coming" className="main_title s-128 glitcher">Coming</div>
                    <div title="soon" className="main_title s-128 glitcher">soon</div>
                </div>
            </div>
            {/* <div className="aimless_text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                <br></br><br></br>
                Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue.
                <br></br><br></br><br></br>
                Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                <br></br>
                Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue. Fusce vitae eros rutrum, commodo mi non, convallis ex. Sed vitae lorem nibh. Aliquam molestie metus eu elit gravida dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce velit felis, gravida vel mattis eget, molestie non sem. Vestibulum aliquam sagittis neque sit amet congue.
            </div> */}
            {/* <div className="text_blur"></div> */}

            {/* <img src={BluredText} style={{height: "100%", maxWidth: "100%"}} />
            <div className="centroid">
                <div className='main_title s-128 glitcher' title="/coming soon//">
                    /coming soon//
                </div>
            </div> */}
        </div>
    );
}