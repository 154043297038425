import $ from "jquery";
var count = false;
var f = false;
class Centroid{
  constructor (){
    this.stop = false;
  }

  checkBlocks = () => {
        if(document.querySelector('.mobile-nav-item-active') != null)
          document.querySelector('.sli_adnd_mob .startglitcher_mob').style.opacity = '0';
        setTimeout(function(){
          if(window.innerWidth >= window.innerHeight) document.querySelector('.main').style.transition= '1s';
          setTimeout(function(){
            if(document.querySelector('.mobile-nav-item-active') == null)
              document.querySelector('.sli_adnd_mob .startglitcher_mob').style.opacity = '1';
            if(document.querySelector('.startglitcher_mob.home') == null){
              document.querySelector('.main').style.display = "flex";
              console.log('bbbbbbbbbbbbbb')
            }
          }, 200);
        }, 800)
    // if(window.innerWidth >= window.innerHeight){
    //   setTimeout(function(){
    //         // document.querySelector('.main').style.display = "flex";
    //   }, 800)
    // }
  }

  NullCount = () => {
    count = false;
  }
  NullF = () => {
    f = false;
  }
  normilizeMain = () => {
    $(".bluring").css("backdrop-filter", "blur(0px)");
    $(".main").css("opacity", "1");
    $(".container-blur").css("left", "200vw");
  }
  quad(timeFraction) {
    return 2*Math.pow(timeFraction, 4)
  }
  quad2(timeFraction) {
    return Math.pow(timeFraction, 4)
  }
  stopAction(){
    this.stop = true;
  }
  centerBlur = (ctrValue = 0, direction = "forward", oneDirection=false, faasted=false) => {
    if($('.mobile-nav .mobile-nav-item-active')[0] != undefined){
      if(document.querySelector('.mobile-nav .mobile-nav-item-active').getAttribute('data-selm') == document.querySelector('.hid_act_name').innerHTML){
        if(!count) this.checkBlocks();
        count = true;
      }
    }
    // console.log(faasted)
      if(this.stop){
        this.stop = false;
        this.normilizeMain()
        return;
      }
      var maxValue = 200;
      var delayTime = 1;
      if(faasted) maxValue = 0;
  
      var delayBetweenChangeDirection = 1;
      
      var opacityValue = Math.ceil((1 - (ctrValue)/(maxValue)) * 100) / 100;
      var blurValue = maxValue*(this.quad(ctrValue/maxValue))
      let blur = `blur(${blurValue}px)`;
      $(".container-blur").css("left", "0");
      
      $(".bluring").css("backdrop-filter", blur);
      $(".main").css("opacity", opacityValue);
      if (direction == "forward"){
        if (blurValue < maxValue){
          if(window.innerWidth >= window.innerHeight) setTimeout(() => {this.centerBlur(++ctrValue, "forward", oneDirection)}, delayTime)
          return
        } else {
          if (!oneDirection){
            setTimeout(() => {this.centerBlur(++ctrValue, "backward", oneDirection)}, delayBetweenChangeDirection + delayTime);
          } else {
            this.normilizeMain();
            return;
          }
        }
      } else if (direction == "backward"){
        if (blurValue > 0.5){
          setTimeout(() => {setTimeout(() => {this.centerBlur(ctrValue -= 0.9, "backward", oneDirection); f = true;}, delayTime)}, (!f) ? 200 : 0);
          return
        } else {
          this.normilizeMain();
        }
      }
  }
  rightTextNotmilize = (color) => {
    $(".rightNav_item").css("text-shadow", `0 0 0 ${color}`);
  }
  rightTextBlur = (ctrValue = 0, direction = "forward", start = "black") => {
    var maxValue = 50;
    var delayTime = 20;
    var delayBetweenChangeDirection = 1000;
    var blurValue = maxValue*(2*this.quad2(ctrValue/maxValue))
    let blur = `${blurValue}px`;
    let color;
    if (start == "black" && direction == "forward"){
      color = "#000000";
    } else if (start == "black" && direction == "backward"){
      color = "#FFFFFF";
    } else if (start == "white" && direction == "forward"){
      color = "#FFFFFF";
    } else if (start == "white" && direction == "backward"){
      color = "#000000";
    }

    $(".rightNav_item").css("text-shadow", `0 0 ${blur} ${color}`);

    if (direction == "forward"){
      if (blurValue < maxValue){
        setTimeout(() => {this.rightTextBlur(++ctrValue, "forward", start)}, delayTime)
        return;
      } else {
        setTimeout(() => {this.rightTextBlur(++ctrValue, "backward", start)}, delayTime + delayBetweenChangeDirection)
      }
    } else if (direction == "backward"){
      if (blurValue > 0.5){
        setTimeout(() => {this.rightTextBlur(--ctrValue, "backward", start)}, delayTime)
        return;
      } else {
        this.rightTextNotmilize(color);
      }
    }
  }


  rightTextNormilizeClasses = () => {
    $(".rightNav_item").removeClass("forwardBlack");
    $(".rightNav_item").removeClass("forwardWhite");
    $(".rightNav_item").removeClass("backwardBlack");
    $(".rightNav_item").removeClass("backwardWhite");
  }
  
  rightTextBlurStartBlack = (stage = 0) => {
    this.rightTextNormilizeClasses();
    switch(stage){
      case 0:
        $(".rightNav_item").addClass("forwardBlack");
        setTimeout(()=>{$(".rightNav_item").css("display", "none");}, 1)
        setTimeout(() => {
          this.rightTextBlurStartBlack(1);
        }, 650);
        break;
      case 1:
        $(".rightNav_item").css("display", "block");
        $(".rightNav_item").addClass("backwardWhite");
        
        break;
    }
    return;
  }
  rightTextBlurStartWhite = (stage = 0) => {
    this.rightTextNormilizeClasses();
    switch(stage){
      case 0:
        $(".rightNav_item").addClass("forwardWhite");
        setTimeout(()=>{$(".rightNav_item").css("display", "none");}, 600)
        setTimeout(() => {
          this.rightTextBlurStartWhite(1)
        }, 1100);
        break;
      case 1:
        $(".rightNav_item").css("display", "block");
        $(".rightNav_item").addClass("backwardBlack");
        break;
    }
    return;
  }
}


export { Centroid };